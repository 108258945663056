<template>
  <div id="Subscribe">
    <app-menu></app-menu>
    <div class="row">
      <div class="col-lg-10 offset-lg-1">
        <div class="col-lg-12">
          <form>
            <div class="form-group">
              <label>Nadpis</label>
              <input v-model="email.title" type="text" class="form-control"
                     placeholder="Nadpis">
            </div>
            <div class="form-group">
              <label>Podnadpis</label>
              <input v-model="email.subtitle" type="text" class="form-control"
                     placeholder="Poznámka pod textem">
            </div>
            <div class="form-group">
              <label>Text</label>
              <wysiwyg v-model="email.text"></wysiwyg>
            </div>
          </form>
          <div class="text-center pt-lg-4">
            <send-button
                :send="send"
                text="Odeslat email"
                ref="sendBtnRef"
            />
          </div>
        </div>
      </div>
    </div>
    <error-modal :message="error"/>
  </div>
</template>

<script>
import AdminMenu from "@/components/admin/AdminMenu";
import axios from "@/api/axiom-instance";
import ErrorModal from "@/components/admin/ErrorModal";
import SendButton from "@/components/admin/SendButton";

export default {
  name: "Subscribe",
  components: {
    'app-menu': AdminMenu,
    'error-modal': ErrorModal,
    'send-button': SendButton
  },
  data() {
    return {
      email: {},
      error: null,
    }
  },
  methods: {
    send: function () {
      axios.post("/email", this.email).then(() => {
        this.email = {}
        this.$refs.sendBtnRef.enable();
      }).catch(error => {
        this.error = error;
        this.$refs.sendBtnRef.enable();
      })
    }
  }
}
</script>

<style scoped>
#Subscribe {
  background-color: white;
  height: 100vh;
  overflow: hidden;
}

</style>

<style src="vue-wysiwyg/dist/vueWysiwyg.css"></style>
